header img{
  padding: 0 30px;
}

#main-menu{
  background-color: rgba(0,0,0,0);
}
.hidden{
  display: none;
}
.lg-sub-html{
  top: 45px;
  background: no-repeat;
  z-index: 1079;
}
a:focus{
  outline: none!important;
}